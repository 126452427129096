@import '../../../assets/scss/abstracts/index';

.login-wrapper {
    width: 100%;
    min-height: 100vh;
    @include flexbox(row, center, center);

    @include media-max(lg) {
        padding: 12px 0px;
    }

    .login {
        @include flexbox(row, center, stretch);
        box-shadow: 0px 4px 25px 0px #1A66A71A;

        &__img {
            position: relative;

            @include media-max(lg) {
                margin-top: 24px;
            }

            .logo {
                text-align: center;

                @include media-min(lg) {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            img {
                max-width: 100%;
                object-fit: cover;
            }
        }

        &__form {
            position: relative;
            padding: 24px 32px;
            max-width: 425px;
            margin: auto;

            @include media-max(lg) {
                border-radius: 10px;
            }

            @include media-max(sm) {
                min-width: 325px;
                max-width: 325px;
            }

            &--title {
                color: $primary-heading-color;
                font-size: 42px;

                @include media-max(lg) {
                    text-align: center;
                }
            }

            &--text {
                color: $primary-text-color;
                font-size: 18px;

                @include media-max(lg) {
                    text-align: center;
                }
            }

            &--error-msg {
                color: $danger;
            }

            .logo {
                margin-bottom: 2rem;

                @include media-min(lg) {
                    display: none;
                }
            }
        }
    }

}