@import '../../../assets/scss/abstracts/index';

.customer-location-mapping-page {
    .layout {
        &__subheader {
            .react-select-container {
                @include media-min(lg) {
                    min-width: 375px;
                }

                @include media-between(md, lg) {
                    min-width: 300px;
                }

                @include media-max(md) {
                    min-width: 275px;
                }
            }
        }
    }
}