.react-select-container {
    width: 100%;
    max-width: 100%;

    .react-select {

        &__control {
            cursor: pointer;
            min-height: unset;
            height: 36px;

            &--is-focused,
            &--menu-is-open {
                border-color: $active-text-color;
                box-shadow: 0 0 5px rgba(53, 71, 172, .75);
                transition: border-color 500ms ease, box-shadow 500ms ease;
            }
        }

        &__input-container {
            @include font(14px, 600);
        }

        &__value-container {
            padding: 0px 8px;
        }

        &__placeholder {
            @include font(14px, 600);
        }

        &__option {
            @include font(14px, 600);
            margin: 4px 0px;
            cursor: pointer;

            &--is-selected {
                background-color: $primary-color;
                color: $white;
            }
        }

        &__single-value {
            @include font(14px, 600);
        }

        &__menu-notice {
            &--no-options {
                @include font(14px, 600);
            }
        }
    }
}