.sidebar {
    grid-area: sidebar;
    background-color: $white;
    border-radius: 10px;
    max-width: 240px;

    @include media-min(xl) {
        min-width: 100%;
    }

    @include media-between(lg, xl) {
        min-width: 240px;
    }

    @include media-min(lg) {
        position: -webkit-sticky;
        position: sticky;
        top: 7rem;
        display: block !important;
        height: calc(100vh - 129px);
        padding-left: .25rem;
        margin-left: -.25rem;
        overflow-y: auto;
    }

    &__menu {
        width: 100%;

        &--title {
            display: flex;
            color: $quaternary-color;
            @include font(16px, 600);

            &:hover {
                color: $active-text-color;
            }

            &.btn-basic {
                &:hover {
                    .svg_icon {
                        @include svg-icon($active-text-color);
                    }
                }
            }
        }

        .list-group {
            width: 100%;

            @include media-min(lg) {
                padding: 15px 0px;
            }

            .list-group-item {
                padding: 15px 20px;
                border: none;
            }

            .list-group-item-link {
                @include flexbox($direction: row, $justify: flex-start, $align: center);
                width: 100%;

                &:hover,
                &.active {
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        width: 3px;
                        top: 0;
                        bottom: 0;
                        left: -24px;
                        background-color: $active-text-color;
                        border-radius: 0px 5px 5px 0px;
                    }

                    .link-icon-wrapper {
                        .svg_icon {
                            @include svg-icon($active-text-color);
                        }
                    }

                    .sidebar__menu--title {
                        color: $active-text-color;
                    }
                }
            }
        }

        &--innerlist {
            .innerlist-title {
                @include font(14px, 500);
                color: $quaternary-color;

                .list-group-item-link {

                    &:hover,
                    &.active {
                        &::before {
                            content: none;
                        }
                    }
                }
            }
        }
    }

    .sidebar__menu--title {
        &.btn-basic {
            .caret-down-icon {
                transition: rotate 0.4s;
            }

            &[aria-expanded="true"] {
                * {
                    color: $primary-color;
                }

                .caret-down-icon {
                    rotate: 180deg;
                }

                .svg_icon {
                    @include svg-icon($primary-color);
                }
            }
        }
    }
}