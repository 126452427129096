@import '../../../assets/scss/abstracts/index';

.layout-income-report {
    .layout {
        &__subheader {
            .ca-datepicker {
                .react-datepicker-wrapper {
                    @include media-min(sm) {
                        min-width: 325px;
                    }
                }
            }
        }
    }
}