.layout {
    border-radius: 10px;

    // &__header {}

    &__subheader {
        &--title {
            @include font(15px, 600);
            color: $primary-text-color;
            margin-bottom: 0;
        }
    }

    // &__body{}

    &--spacing {
        padding: 20px;
    }

    .btn {
        @include media-max(sm) {
            &--addNew {
                padding: 8px;
            }
        }
    }
}