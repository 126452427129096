.dropdown {
    .dropdown-toggle {
        &::after {
            content: none;
        }
    }

    .dropdown-menu {
        border: none;
        box-shadow: 0px 8px 35px 0px #0000001A;

        .dropdown-item {
            padding: 12px 16px;

            &.active,
            &:active {
                color: $primary-color;
                text-decoration: none;
                background-color: $background-color;
            }
        }
    }
}