// Logo.scss

.logo {
    &--small {
      img {
        max-width: 84px;
        height: auto;
      }
    }
  
    &--medium {
      img {
        max-width: 168px;
        height: auto;
      }
    }
  
    &--large {
      img {
        max-width: 336px;
        height: auto;
      }
    }
    
    &--responsive {
      img {
        @include media-min(xl){
          max-width: 168px;
          height: auto;
        }

        @include media-between(lg, xl){
          max-width: 147px;
          height: auto;
        }

        @include media-between(md, lg){
          max-width: 126px;
          height: auto;
        }

        @include media-max(md){
          max-width: 105px;
          height: auto;
        }
      }
    }
  }
  