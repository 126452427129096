// _mixins.scss

@mixin heading-styles($color: $primary-heading-color, $fontSize: 30px, $weight) {
    color: $color;
    font-size: $fontSize;
    font-weight: $weight;
}

// Mixin for applying font family and weight
@mixin font($fontSize, $weight) {
    font-size: $fontSize;
    font-weight: $weight;
}

// Flexbox mixin
@mixin flexbox($direction: row, $justify: flex-start, $align: stretch) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}

// Box shadow mixin
@mixin box-shadow($x, $y, $blur, $color: rgba(0, 0, 0, 0.1)) {
    box-shadow: $x $y $blur $color;
}

// Text overflow mixin
@mixin text-overflow() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Media query mixin
@mixin media-query($breakpoint) {
    @media screen and (min-width: $breakpoint) {
        @content;
    }
}

// _mixins.scss

// Mixin for button states with optional font and border color and width
@mixin button-states($bg-color, $font-color: $white, $border-color: null, $border-width: 1px) {
    background-color: $bg-color;
    color: $font-color;
    border: $border-width solid if($border-color != null, $border-color, $bg-color);

    &:hover, &:focus, &:focus-visible, &:focus-within {
        background-color: darken($bg-color, 10%);
        color: $font-color;
        border-color: if($border-color != null, darken($border-color, 10%), darken($bg-color, 10%));
    }

    &:active, &.active {
        background-color: darken($bg-color, 15%);
        color: $font-color;
        border-color: if($border-color != null, darken($border-color, 15%), darken($bg-color, 15%));
    }

    &:disabled {
        background-color: lighten($bg-color, 30%);
        color: $font-color;
        border-color: if($border-color != null, lighten($border-color, 30%), lighten($bg-color, 30%));
        cursor: not-allowed;
    }

    &:first-child {
        &:active {
            background-color: darken($bg-color, 15%);
            color: $font-color;
            border-color: if($border-color != null, darken($border-color, 15%), darken($bg-color, 15%));
        }
    }
}

// Mixin for button sizes
@mixin button-size($padding, $font-size, $border-radius) {
    padding: $padding;
    font-size: $font-size;
    border-radius: $border-radius;
}

// Mixin for SVG Icons
@mixin svg-icon($color) {
    &--fill {
        fill: $color;
    }
    &--outline {
        fill: none;
        stroke: $color;
    }
}