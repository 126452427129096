.react-table-pagination {
    .pagination {
        &_btn {
            @extend %btn;
            @include font (14px, 500);
            color: $primary-text-color;
            background-color: $transparent;

            @include media-min(sm) {
                margin-inline: 4px;
                padding: 8px 12px;
            }

            @include media-max(sm) {
                margin-inline: 2px;
                padding: 8px 10px;
            }

            &:hover {
                color: $active-text-color;
                background-color: $transparent;
            }

            &:active {
                color: $active-text-color;
                background-color: $transparent;
                border-radius: 3px;
            }

            &.active {
                background-color: $primary-color;
                color: $white;
                border-radius: 3px;

                &:hover {
                    background-color: darken($primary-color, 10%);
                }

                &:active {
                    background-color: darken($primary-color, 15%);
                }
            }

            &--first,
            &--prev,
            &--next,
            &--last {
                @extend %btn;
                color: $secondary-text-color;
                background-color: $white;

                &:hover {
                    background-color: darken($white, 2%);
                    color: $secondary-text-color;
                }

                &:disabled {
                    color: $secondary-text-color;
                    background-color: $white;
                }
            }
        }
    }
}