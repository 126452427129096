.checkbox {
    position: relative;
    display: inline-block;

    &__input {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        z-index: -1;
        position: absolute;
        left: -10px;
        top: -8px;
        display: block;
        margin: 0;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: rgba(0, 0, 0, 0.6);
        box-shadow: none;
        outline: none;
        opacity: 0;
        transform: scale(1);
        pointer-events: none;
        transition: opacity 0.3s, transform 0.2s;

        &:checked {
            background-color: $primary-color;

            &+.checkbox__box::before {
                border-color: $primary-color;
                background-color: $primary-color;
            }

            &+.checkbox__box::after {
                border-color: $white;
            }
        }

        &:indeterminate {
            background-color: $primary-color;

            &+.checkbox__box::before {
                border-color: $primary-color;
                background-color: $primary-color;
            }

            &+.checkbox__box::after {
                border-left: none;
                transform: translate(4px, 3px);
            }
        }

        &:hover {
            opacity: 0.04;

            &:focus {
                opacity: 0.16;
            }
        }

        &:active {
            opacity: 1;
            transform: scale(0);
            transition: transform 0s, opacity 0s;

            &:checked {
                &+.checkbox__box::before {
                    border-color: #85b8b7;
                }

                &:active+.checkbox__box::before {
                    border-color: transparent;
                    background-color: rgba(0, 0, 0, 0.6);
                }
            }
        }

        &:disabled {
            opacity: 0;

            &+.checkbox__box {
                color: rgba(0, 0, 0, 0.38);
                cursor: initial;

                &::before {
                    border-color: currentColor;
                }
            }

            &:checked+.checkbox__box::before,
            &:indeterminate+.checkbox__box::before {
                border-color: transparent;
                background-color: currentColor;
            }
        }
    }

    &__box {
        display: inline-block;
        width: 100%;
        cursor: pointer;

        &::before {
            content: "";
            display: inline-block;
            box-sizing: border-box;
            border: solid 2px;
            border-color: rgba(0, 0, 0, 0.6);
            border-radius: 2px;
            width: 18px;
            height: 18px;
            vertical-align: top;
            transition: border-color 0.2s, background-color 0.2s;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 40%;
            left: 50%;
            width: 10px;
            height: 5px;
            border: solid 2px transparent;
            border-right: none;
            border-top: none;
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
}