// _buttons.scss

// Basic button styles
%btn {
    @include flexbox(row, center, center);
    padding: $spacing-sm $spacing-md;
    font-family: $primary-font;
    font-size: 14px;
    line-height: $line-height-base;
    font-weight: $fw-semibold;
    border: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease;

    &:first-child {
        &:active {
            background-color: darken($primary-color, 15%);
        }
    }
}

.btn {
    @extend %btn;
}

// Primary button
.btn.btn--primary {
    @include button-states($primary-color, $white, $primary-color);
    @include button-size(12px 16px, 14px, $border-radius-sm);

    &.btn-check:checked+&,
    &.btn.active,
    &.btn.show,
    &.btn:first-child:active,
    &:not(.btn-check)+& {
        background-color: darken($primary-color, 15%);
        color: $white;
        border-color: darken($primary-color, 15%);
    }
}

// Secondary button
.btn.btn--secondary {
    @include button-states($secondary-color, $white, $secondary-color);
    @include button-size(12px 16px, 14px, $border-radius-sm);

    &.btn-check:checked+&,
    &.btn.active,
    &.btn.show,
    &.btn:first-child:active,
    &:not(.btn-check)+& {
        background-color: darken($secondary-color, 15%);
        color: $white;
        border-color: darken($secondary-color, 15%);
    }
}

// Basic button
.btn.btn-basic {
    @include button-states($transparent, $primary-text-color, $transparent);
    border: none;
}

// Outline button
.btn.btn-primary-outline {
    @include button-states($white, $primary-color);
    border: 1px solid $primary-color;

    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
        background-color: darken($white, 5%);
        border: 1px solid $primary-color;
        border-color: $primary-color;
    }

    &:first-child {
        &:active {
            border-color: $primary-color;
        }
    }
}

.btn.btn-secondary-outline {
    @include button-states($white, $secondary-color);
    border: 1px solid $secondary-color;

    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
        background-color: darken($white, 5%);
        border: 1px solid $secondary-color;
        border-color: $secondary-color;
    }

    &:first-child {
        &:active {
            border-color: $secondary-color;
        }
    }
}

// Actions button
.btn.btn-action {
    @include button-states($action-btn-bg-color);
    padding: calc($spacing-sm / 2);
}

// Danger button
.btn.btn--danger {
    @include button-states($danger);
}

// Small button
.btn--small {
    @include button-size($spacing-xs $spacing-sm, $fs-sm, $border-radius-sm);
}

// Large button
.btn--large {
    @include button-size($spacing-md $spacing-lg, $fs-lg, $border-radius-lg);
}

// Full-width button
.btn--full-width {
    width: 100%;
}