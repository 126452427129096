$toast-colors: (
    success: $success-dark,
    error: $danger,
    warning: $warning,
    info: $info
);

.toast-container {
    max-width: 325px;
}

.toast {
    background-color: $white;

    &-header {
        top: 32px;
        right: 28px;

        .btn-close {
            font-size: 14px;
            margin: 0;
            padding: 0;
        }
    }

    &-body {
        border-radius: 10px;

        &__content {
            border-left: 4px solid $transparent;

            .svg_icon--outline {
                fill: none;
                stroke: currentColor;
            }
        }

        &__message {
            color: inherit;
        }
    }

    @each $variant, $color in $toast-colors {
        &--#{$variant} {
            color: $color;

            & .toast-body__content {
                border-color: $color;
            }
        }
    }
}