@import '../../../assets/scss/abstracts/index';

.location-machines-page {
    .layout {
        &__subheader {
            &--title {
                @include font(20px, 600);
            }

            .ca-datepicker {
                .react-datepicker-wrapper {
                    @include media-min(sm) {
                        min-width: 325px;
                    }
                }
            }
        }

        &__body {
            .location-info {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 16px;

                @include media-between(sm, md) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include media-between(md, xl) {
                    grid-template-columns: repeat(3, 1fr);
                }

                @include media-min(xl) {
                    grid-template-columns: repeat(5, 1fr);
                }

                .card {
                    .card-body {
                        .card-title {
                            @include font(14px, 600);
                            color: $quaternary-color;
                        }

                        .card-text {
                            @include font(16px, 500);
                        }
                    }
                }
            }
        }
    }
}