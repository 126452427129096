// _variables.scss

// Primary colors
$primary-color: #3547AC;
$secondary-color: #F1582F;

// Additional colors
$tertiary-color: #0D1458;
$quaternary-color: #848BA2;
$background-color: #F0F2FA;

// Text colors
$primary-text-color: #323232;
$secondary-text-color: #848BA2;
$active-text-color: #3547AC;

// Heading text colors
$primary-heading-color: #0D1458;

// Default colors
$white: #FFF;
$black: #000;
$transparent: transparent;
$danger: #FD2020;
$success: #18BA3C;
$success-dark: #198754;
$warning: #FFA700;
$info: #0DCAF0;

// Input Color Variables
$input-bg-color: #FAFCFE;
$input-placeholder-color: #898A8B;
$input-border-color: #DBE5F1;

// Table Colors
$table-heading-color: #848BA2;
$table-body-color: #323232;
$table-border-color: #EFEFEF;
$table-head-bg-color: #EFF4F9;

// Modal Colors
$close-btn-color: #B2B2B3;

// Border Colors
$border-color: #F0F2FA;

// Other Color Variables
$gray-shade-1: #F7F7F7;
$gray-shade-2: #5C5D5F;
$gray-shade-3: #757577;
$process-status-color: #FF8B03;
$action-btn-bg-color: #e4e9fd;

// Alerts Colors
$alert-danger-color: #FD2020;
$alert-danger-bg-color: #FFF4F4;
$alert-danger-border-color: #FF9C9C;

// Font Family Variables
$primary-font: 'PrimaryFont';
$secondary-font: 'SecondaryFont';
$heading-font: 'HeadingFont';

// Font Weight Variables
$fw-light: 300;
$fw-normal: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;

// Font sizes
$fs-base: 16px;
$fs-sm: 14px;
$fs-lg: 18px;
$fs-xl: 24px;

// Font Path Variables
$font-path: '/assets/fonts';

// Line heights
$line-height-base: 1;
$line-height-heading: 1.2;

// Spacing scale (multiples of 8px)
$spacing-xs: 4px;
$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-lg: 24px;
$spacing-xl: 32px;
$spacing-xxl: 48px;

// Breakpoints for responsive design
$breakpoint-xs: 480px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1440px;
$breakpoint-xxxl: 1600px;

// Z-index scale
$z-index-dropdown: 1000;
$z-index-modal: 1050;
$z-index-tooltip: 1070;

// Border radius values
$border-radius-sm: 5px;
$border-radius-md: 10px;
$border-radius-lg: 16px;