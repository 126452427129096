.modal {
    .modal-dialog {
        .modal-content {
            width: 100%;

            .modal-header {
                .title {
                    @include heading-styles($primary-heading-color, 24px, 700)
                }

                .svg_icon {
                    @include svg-icon($close-btn-color);
                }
            }

            // .modal-body {}
            .modal-footer {
                justify-content: center;

                .btn {
                    padding: 12px 24px;
                    min-width: 100px;
                }
            }
        }
    }
}