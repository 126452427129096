.toggle-switch-wrapper {
    display: flex;
    align-items: center;
}

.toggle-switch__label {
    font-size: 13px;
    margin: 0 6px;
}

.toggle-switch {
    &__input {
        visibility: hidden;
        display: none;
    }

    &__toggle {
        position: relative;
        display: block;
        width: 40px;
        height: 20px;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        transform: translate3d(0, 0, 0);

        &:before {
            content: "";
            position: relative;
            top: 50%;
            left: 3px;
            width: 34px;
            height: 10px;
            display: block;
            background-color: $primary-color;
            border-radius: 8px;
            transform: translateY(-50%);
            transition: background-color 0.2s ease;
        }

        & .toggle-switch__slider {
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            display: block;
            background-color: $secondary-color;
            border-radius: 10px;
            box-shadow: 0 3px 8px rgba(154, 153, 153, 0.5);
            transition: all 0.2s ease;

            &:before {
                content: "";
                position: absolute;
                display: block;
                margin: -18px;
                width: 56px;
                height: 56px;
                background-color: rgba(79, 46, 220, 0.5);
                border-radius: 50%;
                transform: scale(0);
                opacity: 1;
                pointer-events: none;
            }
        }
    }

    &__input:checked+.toggle-switch__toggle:before {
        background-color: $close-btn-color;
    }

    &__input:checked+.toggle-switch__toggle .toggle-switch__slider {
        background-color: $secondary-color;
        transform: translateX(20px);
        transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background-color 0.15s ease;
        box-shadow: 0 3px 8px rgba(79, 46, 220, 0.2);

        &:before {
            transform: scale(1);
            opacity: 0;
            transition: all 0.4s ease;
        }
    }
}