@import '../../../assets/scss/abstracts/index';

.customer-location-page {
    .layout {
        &__header {
            .title {
                .customer-name {
                    @include font(16px, 500);
                    color: $primary-text-color;
                }
            }
        }

        &__subheader {
            &--title {
                @include font(20px, 600);
            }
        }

        &__body {
            .customer-info {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 16px;

                @include media-between(sm, md) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include media-between(md, xl) {
                    grid-template-columns: repeat(3, 1fr);
                }

                @include media-min(xl) {
                    grid-template-columns: repeat(4, 1fr);
                }

                .card {
                    .card-body {
                        .card-title {
                            @include font(14px, 600);
                            color: $quaternary-color;
                        }

                        .card-text {
                            @include font(16px, 500);
                        }
                    }
                }
            }
        }
    }
}