@import '../../../assets/scss/abstracts/index';

.location-report-table {
    .react-table {
        .table {
            &__body {
                &--td {
                    &:last-child {
                        .svg_icon {
                            cursor: pointer;
                        }
                    }
                }

                .react-table {
                    .table {
                        border: 1px solid $table-border-color;

                        &__head {

                            &--th {
                                background-color: $table-head-bg-color;
                            }
                        }

                        &__body {
                            &--td {
                                height: unset;
                                color: $gray-shade-3;
                                @include font(14px, 400);
                            }
                        }
                    }
                }
            }
        }
    }
}