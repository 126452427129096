.header {
    background-color: $background-color;
    padding: 15px 30px;

    &__profile {
        &--username {
            color: $active-text-color;
        }

        &--avatar {
            img {
                width: 53px;
                height: 53px;
                border: 1px solid $active-text-color;

                @include media-max(sm) {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        .dropdown {
            .dropdown-toggle {
                .svg_icon--outline {
                    fill: none;
                    stroke: $primary-color;
                    rotate: 0deg;
                    transition: rotate 0.4s;
                }

                &::after {
                    content: none;
                }

                &.show {
                    .svg_icon--outline {
                        rotate: 180deg;
                    }
                }
            }

            .dropdown-menu {
                .dropdown-item {
                    @include flexbox($direction: row, $justify: flex-start, $align: center);
                    @include font(14px, 500);
                    padding: 8px 16px;
                    color: $quaternary-color;

                    &:hover {
                        color: $active-text-color;

                        .svg_icon--outline {
                            stroke: $active-text-color;
                        }
                    }
                }
            }
        }
    }
}