@import '../../../assets/scss/abstracts/index';

.profile-page {
    .layout {
        .seperator {
            @include media-min(md) {
                width: 1px;
                height: 100%;
                background-color: $quaternary-color;
            }

            @include media-max(md) {
                width: 100%;
                height: 1px;
                background-color: $quaternary-color;
            }
        }

        &__body {
            .form {
                &__myinfo {
                    .card {
                        &-img {
                            width: 6.25rem;
                            height: 6.25rem;

                            &--delete {
                                cursor: pointer;
                                background-color: $white;
                                width: 24px;
                                height: 24px;

                                .svg_icon {
                                    @include svg-icon($secondary-color);
                                }
                            }

                            &--filename {
                                font-size: 12px;

                                span {
                                    max-width: 80px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}