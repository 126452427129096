.nav {
    flex-wrap: nowrap;
    overflow: auto;
    border-bottom: 1px solid $border-color;

    @include media-max(sm) {
        gap: 1.5rem;
    }

    @include media-between(sm, md) {
        gap: 3rem;
    }

    @include media-min(md) {
        gap: 5rem;
    }
    .nav-item {
        .nav-link {
            color: $secondary-text-color;
            @include font(16px, 600);

            &.active,
            &:hover {
                color: $active-text-color;
                border-bottom-color: $active-text-color;
            }
        }
    }
}