// base/_typography.scss

// Define the custom fonts
@font-face {
    font-family: 'PrimaryFont';
    src: url("#{$font-path}/Geist/statics-ttf/Geist-Light.ttf") format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'PrimaryFont';
    src: url("#{$font-path}/Geist/statics-ttf/Geist-Regular.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PrimaryFont';
    src: url("#{$font-path}/Geist/statics-ttf/Geist-Medium.ttf") format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'PrimaryFont';
    src: url("#{$font-path}/Geist/statics-ttf/Geist-SemiBold.ttf") format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'PrimaryFont';
    src: url("#{$font-path}/Geist/statics-ttf/Geist-Bold.ttf") format('truetype');
    font-weight: bold;
    font-style: normal;
}

body {
    font-family: $primary-font;
    font-size: 16px;
    font-weight: $fw-normal;
    line-height: 1.5;
}

.title {
    @include heading-styles($primary-heading-color, 30px, 700);
}

.primary-text {
    color: $primary-color;
}

.secondary-text {
    color: $secondary-color;
}

.tertiary-text {
    color: $tertiary-color;
}

.quaternary-text {
    color: $quaternary-color;
}

.error-message {
    color: $danger;
    font-size: 12px;
    margin-bottom: 0;
}

.input-required-icn {
    color: $danger;
}

.cursor-pointer {
    cursor: pointer;
}