// components/_card.scss

.card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    background-color: #FFF;

    &__title {
        font-size: 18px;
        font-weight: bold;
    }

    &__content {
        margin-top: 10px;
    }

    &__footer {
        margin-top: 20px;
        font-style: italic;
    }

    &--highlighted {
        background-color: #ff0;
    }
}
