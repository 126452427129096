@import '../../../assets/scss/abstracts/index';

.dashboard {
    .layout {
        .react-select-container {
            width: 100%;
            max-width: 200px;

            @include media-min(md) {
                max-width: 250px;
            }
        }

        .recent-payments {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 16px;

            @include media-between(sm, md) {
                grid-template-columns: repeat(2, 1fr);
            }

            @include media-between(md, xl) {
                grid-template-columns: repeat(3, 1fr);
            }

            @include media-min(xl) {
                grid-template-columns: repeat(5, 1fr);
            }

            .card {
                border: none;

                @include media-min(xl) {
                    &:nth-child(-n + 4) {
                        border-right: 2px solid $border-color;
                    }
                }

                @include media-between(md, xl) {

                    &:nth-child(-n + 2),
                    &:nth-last-child(2) {
                        border-right: 2px solid $border-color;
                    }
                }

                @include media-between(sm, md) {
                    &:nth-child(odd):not(:nth-last-child(-n+1)) {
                        border-right: 2px solid $border-color;
                    }
                }
            }

            .card-body {
                .card-title {
                    color: $gray-shade-2;

                    .card-icon {
                        height: 45px;
                        width: 45px;
                        border-radius: 5px;
                        background-color: $gray-shade-1;
                    }
                }
            }
        }
    }
}