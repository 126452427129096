.app-wrapper {
    background-color: $background-color;
    width: 100%;
    min-height: calc(100vh - 99px);

    .app-body-wrapper {

        @include media-min(sm) {
            margin-top: 91px;
            padding: 15px 30px;
        }

        @include media-max(sm) {
            margin-top: 76px;
            padding: 15px;
        }

        @include media-min(lg) {
            display: grid;
            grid-template-areas: "sidebar main";
            grid-template-columns: minmax(240px, 1fr) 5fr;
            gap: 1.5rem;
        }

        .app-body {
            .content__wrapper {
                border-radius: $border-radius-md;

                &--bgwhite {
                    background-color: $white;
                }

                &--bgtransparent {
                    background-color: $transparent;
                }

                &--spacing {
                    padding: 20px;
                }
            }
        }
    }
}