.react-table {
    .table {
        &__head {
            // &--row {}

            &--th {
                @include font(14px, 700);
                color: $table-heading-color;
                min-width: max-content;
                max-width: 250px;
                overflow-wrap: break-word;
            }
        }

        &__body {
            &--row {
                &:last-child {
                    .table__body--td {
                        border-bottom: none;
                    }
                }

                &.tr-hoverable {
                    &:hover {
                        background-color: #00000008;
                    }
                }
            }

            &--td {
                @include font(14px, 400);
                background-color: $transparent;
                color: $table-body-color;
                height: 60px;
                min-width: max-content;
                max-width: 250px;
                overflow-wrap: break-word;

                .dropdown {
                    .dropdown-menu {
                        .dropdown-item {
                            @include font(13px, 500);

                            &:hover {
                                color: $primary-color;
                            }

                            .svg_icon {
                                @include svg-icon($primary-color);
                            }
                        }
                    }
                }

                .status {
                    &__completed {
                        color: $success;
                    }

                    &__pending {
                        color: $secondary-color;
                    }

                    &__failed {
                        color: $danger;
                    }

                    &__processing {
                        color: $process-status-color;
                    }
                }
            }
        }
    }
}