.ca-datepicker {
    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker {
        border: none;
        box-shadow: 0px 12px 35px rgba(0, 0, 0, 0.1);

        &__input-container {
            .form-control {
                background-color: $white;
                color: $primary-text-color;
                @include font(13px, 500);
            }
        }

        &__day {
            &--selected {
                background-color: $primary-color;
                color: $white;
            }

            &--keyboard-selected {
                background-color: $primary-color;
                color: $white;
            }

            &--outside-month {
                color: $input-placeholder-color;
            }

            &--in-range {
                background-color: $primary-color;
                color: $white;
            }

            &--in-selecting-range {
                &:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
                    background-color: lighten($primary-color, 30%);
                    color: $white;
                }
            }
        }

        &__header {
            background-color: $primary-color;
        }

        &__current-month {
            color: $white;
        }

        &__day-name {
            color: $white;
            font-weight: 700;
        }

        &__year-dropdown {
            background-color: $white;
        }

        &__year-option {
            padding: 4px 0px;

            &--selected_year {
                background-color: $primary-color;
                color: $white;
                font-weight: 600;

                &:hover {
                    background-color: $quaternary-color;
                }
            }
        }

        &__year-read-view {
            &--selected-year {
                color: $white;
            }
        }

        &__navigation {
            &--years {
                display: none;
            }
        }
    }

    .react-datepicker-popper {
        .react-datepicker {
            &__triangle {
                stroke: $primary-color;
                fill: $primary-color;
            }
        }

        &[data-placement^=bottom] {
            .react-datepicker {
                &__triangle {
                    color: $primary-color;
                    fill: $primary-color;
                }
            }
        }
    }

    .icon {
        position: absolute;
        right: 12px;
    }
}