// _form.scss

.form-label {
    color: $primary-text-color;
    @include font($fs-sm, $fw-medium);
    margin-bottom: 0.25rem;
}

.form-control,
.form-select {
    background-color: $input-bg-color;
    border: 1px solid $input-border-color;
    border-radius: $border-radius-sm;
    padding: $spacing-sm $spacing-md;

    &::placeholder {
        @include font($fs-sm, $fw-light);
        color: $input-placeholder-color;
    }

    &:focus {
        border-color: $active-text-color;
        box-shadow: 0 0 5px rgba(53, 71, 172, .75);
        transition: border-color 500ms ease, box-shadow 500ms ease;
    }
}

.otp-input {
    div {
        gap: 8px;
    }

    &__input {
        font-size: $fs-xl;
        text-align: center;
        color: $primary-text-color;
        border: none;
        border-bottom: 1px solid $input-border-color;
        outline: none;

        @include media-min(sm) {
            width: 70px !important;
            height: 70px;
        }

        @include media-max(sm) {
            width: 50px !important;
            height: 50px;
        }

        &:focus {
            border-color: $active-text-color;
        }
    }

    span {
        display: none;
    }
}

.search-input-box {
    min-width: 200px;
    max-width: 235px;
    background-size: 20px 20px;
}