// Define breakpoints
$breakpoints: (
  xs: 0px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px
);

// Mixin for min-width
@mixin media-min($breakpoint) {
  @media (min-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}

// Mixin for max-width
@mixin media-max($breakpoint) {
  $max-width: map-get($breakpoints, $breakpoint);
  @media (max-width: calc(#{$max-width} - 0.02px)) {
    @content;
  }
}

// Mixin for min-width and max-width
@mixin media-between($min, $max) {
  $min-width: map-get($breakpoints, $min);
  $max-width: map-get($breakpoints, $max);
  @media (min-width: $min-width) and (max-width: calc(#{$max-width} - 0.02px)) {
    @content;
  }
}
