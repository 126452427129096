@import '../../../assets/scss/abstracts/index';

.drag-drop-zone {
    height: 150px;
    border: 1px dashed #848BA2;
    border-radius: 10px;

    .ddz_container {
        gap: 24px;

        &--icon {
            .svg_icon {
                @include svg-icon($primary-text-color);
            }
        }
    }
}

.ddz_uploadedfiles {
    .list-group {
        .list-group-item {
            .progress {
                background-color: $quaternary-color;
                min-width: 33%;
                height: 6px;
                margin-top: 10px;

                .progress-bar {
                    background-color: $primary-color;
                    height: 6px;
                }
            }
        }
    }
}