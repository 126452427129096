.loader-container {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: rgba(206, 206, 206, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    height: 100%;
    width: 100%;
    border-radius: 10px;

}

.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3547AC;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}